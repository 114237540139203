<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form ref="form" v-model="isValid">
      <v-card-text class="pa-0">
        <v-row>
          <v-col>
            <v-text-field
              dense
              hide-details=""
              :label="
                getTranslation(
                  'user.creationForm.formFieldTitles.firstName',
                  '*'
                )
              "
              required
              v-model="user.firstName"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              hide-details=""
              :label="
                getTranslation(
                  'user.creationForm.formFieldTitles.lastName',
                  '*'
                )
              "
              required
              v-model="user.lastName"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              hide-details=""
              :label="
                getTranslation('user.creationForm.formFieldTitles.email', '*')
              "
              required
              v-model="user.email"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              hide-details=""
              :label="
                getTranslation('user.creationForm.formFieldTitles.username')
              "
              v-model="user.username"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              hide-details=""
              :items="languages"
              :label="
                getTranslation(
                  'user.creationForm.formFieldTitles.language',
                  '*'
                )
              "
              required
              v-model="user.languageId"
              item-text="name"
              item-value="id"
              outlined
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              dense
              hide-details=""
              :label="
                getTranslation(
                  'user.creationForm.formFieldTitles.phoneNumber',
                  '*'
                )
              "
              required
              v-model="user.phoneNumber"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              hide-details=""
              :items="userTypes"
              :label="
                getTranslation('user.creationForm.formFieldTitles.type', '*')
              "
              required
              v-model="user.userTypeId"
              item-text="name"
              item-value="id"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details=""
              :label="getTranslation('changePassword.newPassword', '*')"
              v-model="user.password"
              required
              outlined
              :rules="passwordRules"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              dense
              hide-details=""
              v-if="showNMOField"
              :items="nmos"
              :disabled="isNMOHTCDisabled"
              :label="
                getTranslation('user.creationForm.formFieldTitles.nmo', '*')
              "
              required
              v-model="user.nmoId"
              item-text="name"
              item-value="id"
              outlined
              @change="
                loadHTC();
                resetHTC();
              "
              :rules="[
                () =>
                  ((user.userTypeId == 2 ||
                    user.userTypeId == 3 ||
                    user.userTypeId == 4) &&
                    !!user.nmoId) ||
                  $t('user.creationForm.errors.nmoRequired'),
              ]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details=""
              :label="getTranslation('changePassword.confirmPassword', '*')"
              v-model="passwordConfirmation"
              required
              outlined
              :rules="passwordConfirmationRules"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              dense
              hide-details=""
              v-if="showHTCField && !!htcs.length"
              :disabled="isNMOHTCDisabled"
              :items="htcs"
              :label="
                getTranslation('user.creationForm.formFieldTitles.htc', '*')
              "
              required
              v-model="user.htcId"
              item-text="name"
              item-value="id"
              outlined
              :rules="[
                () =>
                  !!htcs.length ||
                  (!!user.htcId &&
                    (user.userTypeId == 2 || user.userTypeId == 3)) ||
                  $t('user.creationForm.errors.htcRequired'),
              ]"
              :no-data-text="$t('user.creationForm.hint.htcLoad')"
            ></v-select>
            <p></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              dense
              :label="$t('user.creationForm.formFieldTitles.hasMobileAccess')"
              v-model="user.hasMobileAccess"
              :hide-details="showCanDeleteUtilization"
            ></v-switch>
          </v-col>
          <v-col v-if="showCanDeleteUtilization" cols="12" class="pt-0">
            <v-switch
              :label="
                $t('user.creationForm.formFieldTitles.canDeleteUtilization')
              "
              v-model="user.canDeleteUtilization"
            ></v-switch>
          </v-col>
          <!-- <v-col>
            <v-switch
              :label="$t('user.creationForm.formFieldTitles.isActive')"
              v-model="user.isActive"
            ></v-switch>
                    </v-col>-->
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              v-model="user.notes"
              :label="$t('user.creationForm.formFieldTitles.notes')"
              color="#0071c5"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <div class="d-flex">
      <v-spacer />
      <v-btn color="success" @click="save()" :disabled="isSending || !isValid">
        {{ $t("actions.save") }}
      </v-btn>
      <v-progress-circular v-if="isSending" indeterminate></v-progress-circular>
    </div>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="showSnackbarColor"
      style="top: 8px"
      :timeout="3000"
    >
      {{ snackBarMessage }}
      <v-btn color="white" text @click="showSnackbar = false">CLOSE</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["parameter"],
  data() {
    return {
      isValid: false,
      isEdit: true,
      isSending: false,
      isShowContent: false,
      showSnackbar: false,
      snackBarMessage: "SAVED",
      showSnackbarColor: "success",
      nmos: [],
      htcs: [],
      languages: [],
      userTypes: [],
      user: { password: "" },
      passwordConfirmation: "",
      isNMOHTCDisabled: false,
      passwordRulesTemplate: [
        (v) =>
          this.isEdit ||
          (v && v.length >= 8) ||
          this.$t("changePassword.passwordCharacters"),
        (v) => {
          if (this.isEdit) return this.isEdit;
          if (v && v.length > 0) {
            const pattern = /^(?=.*[!-\/:-@\[-`{-~]).{8,}$/i; //eslint-disable-line
            return pattern.test(v) || this.$t("changePassword.passwordSpecial");
          } else {
            return this.isEdit || this.$t("changePassword.requirePassword");
          }
        },
      ],
      passwordConfirmationRulesTemplate: [
        (v) => this.isEdit || !!v || this.$t("changePassword.requiredPassword"),
        (v) =>
          (v && v.length >= 7) || this.$t("changePassword.passwordCharacters"),
        (v) =>
          (v && v === this.user.password) ||
          this.$t("changePassword.matchingPassword"),
        (v) => {
          if (this.isEdit) return this.isEdit;
          if (v.length > 0) {
            const pattern = /^(?=.*[!-\/:-@\[-`{-~]).{8,}$/i; //eslint-disable-line
            return pattern.test(v) || this.$t("changePassword.passwordSpecial");
          } else {
            return this.$t("changePassword.requiredPassword");
          }
        },
      ],
    };
  },
  computed: {
    showCanDeleteUtilization() {
      return this.user.id > 0;
    },
    showNMOField: function () {
      return [2, 3, 4].some((x) => x == this.user.userTypeId);
    },
    showHTCField: function () {
      return [2, 3].some((x) => x == this.user.userTypeId);
    },
    passwordRules: function () {
      if (
        this.isEdit &&
        (!this.user.password || this.user.password.length < 1)
      ) {
        return [];
      }
      return this.passwordRulesTemplate;
    },
    passwordConfirmationRules: function () {
      if (
        this.isEdit &&
        (!this.user.password || this.user.password.length < 1)
      ) {
        return [];
      }
      return this.passwordConfirmationRulesTemplate;
    },
  },
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    load() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "user/" + this.parameter.id)
        .then((response) => {
          delete response.data.password;
          this.user = response.data;
          this.loadNMO();
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadNMO() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "nmo/")
        .then((response) => {
          this.nmos = response.data;
          if (this.parameter.id != -1) this.loadHTC();
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadLanguage() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "language/")
        .then((response) => {
          this.languages = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadUserType() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "userType/")
        .then((response) => {
          this.userTypes = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    resetHTC() {
      this.user.htcId = null;
    },
    loadHTC() {
      this.isDataLoading = true;
      if (this.user.nmoId == null) {
        return;
      }
      axios
        .get(
          this.$store.getters.getEnvironment +
            "htc/getbynmoid/" +
            this.user.nmoId
        )
        .then((response) => {
          this.htcs = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    save() {
      if (this.user.id == null) this.insert();
      else this.update();
    },
    insert() {
      this.isSending = true;
      axios
        .post(this.$store.getters.getEnvironment + "user/", this.user)
        .then((response) => {
          this.user.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setUserKey");
          this.close();
        })
        .catch((e) => {});
    },
    update() {
      this.isSending = true;
      axios
        .put(
          this.$store.getters.getEnvironment + "user/" + this.user.id,
          this.user
        )
        .then((response) => {
          this.user.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setUserKey");
          this.close();
        })
        .catch((e) => {});
    },
    close() {
      this.$store.commit("hideModal");
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
  },
  created() {
    if (this.isEdit) this.onSetTitle(this.$t("user.view.edit"));
    else this.onSetTitle(this.$t("user.view.create"));

    this.loadUserType();
    this.loadLanguage();
    if (this.parameter.id != -1) {
      this.isEdit = true;
      this.load();
    } else {
      this.isEdit = false;
      this.loadNMO();
    }
    if (this.parameter.nmoId != -1) {
      this.user.nmoId = this.parameter.nmoId;
      this.user.htcId = this.parameter.htcId;
      this.loadHTC();
      this.isNMOHTCDisabled = this.parameter.origin == "htcDetail";
    }
  },
};
</script>

