var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%","min-height":"100%"}},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
                'user.creationForm.formFieldTitles.firstName',
                '*'
              ),"required":"","outlined":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
                'user.creationForm.formFieldTitles.lastName',
                '*'
              ),"required":"","outlined":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation('user.creationForm.formFieldTitles.email', '*'),"required":"","outlined":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation('user.creationForm.formFieldTitles.username'),"outlined":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.languages,"label":_vm.getTranslation(
                'user.creationForm.formFieldTitles.language',
                '*'
              ),"required":"","item-text":"name","item-value":"id","outlined":""},model:{value:(_vm.user.languageId),callback:function ($$v) {_vm.$set(_vm.user, "languageId", $$v)},expression:"user.languageId"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
                'user.creationForm.formFieldTitles.phoneNumber',
                '*'
              ),"required":"","outlined":""},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.userTypes,"label":_vm.getTranslation('user.creationForm.formFieldTitles.type', '*'),"required":"","item-text":"name","item-value":"id","outlined":""},model:{value:(_vm.user.userTypeId),callback:function ($$v) {_vm.$set(_vm.user, "userTypeId", $$v)},expression:"user.userTypeId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation('changePassword.newPassword', '*'),"required":"","outlined":"","rules":_vm.passwordRules},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.showNMOField)?_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.nmos,"disabled":_vm.isNMOHTCDisabled,"label":_vm.getTranslation('user.creationForm.formFieldTitles.nmo', '*'),"required":"","item-text":"name","item-value":"id","outlined":"","rules":[
              function () { return ((_vm.user.userTypeId == 2 ||
                  _vm.user.userTypeId == 3 ||
                  _vm.user.userTypeId == 4) &&
                  !!_vm.user.nmoId) ||
                _vm.$t('user.creationForm.errors.nmoRequired'); } ]},on:{"change":function($event){_vm.loadHTC();
              _vm.resetHTC();}},model:{value:(_vm.user.nmoId),callback:function ($$v) {_vm.$set(_vm.user, "nmoId", $$v)},expression:"user.nmoId"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.getTranslation('changePassword.confirmPassword', '*'),"required":"","outlined":"","rules":_vm.passwordConfirmationRules},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.showHTCField && !!_vm.htcs.length)?_c('v-select',{attrs:{"dense":"","hide-details":"","disabled":_vm.isNMOHTCDisabled,"items":_vm.htcs,"label":_vm.getTranslation('user.creationForm.formFieldTitles.htc', '*'),"required":"","item-text":"name","item-value":"id","outlined":"","rules":[
              function () { return !!_vm.htcs.length ||
                (!!_vm.user.htcId &&
                  (_vm.user.userTypeId == 2 || _vm.user.userTypeId == 3)) ||
                _vm.$t('user.creationForm.errors.htcRequired'); } ],"no-data-text":_vm.$t('user.creationForm.hint.htcLoad')},model:{value:(_vm.user.htcId),callback:function ($$v) {_vm.$set(_vm.user, "htcId", $$v)},expression:"user.htcId"}}):_vm._e(),_c('p')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"dense":"","label":_vm.$t('user.creationForm.formFieldTitles.hasMobileAccess'),"hide-details":_vm.showCanDeleteUtilization},model:{value:(_vm.user.hasMobileAccess),callback:function ($$v) {_vm.$set(_vm.user, "hasMobileAccess", $$v)},expression:"user.hasMobileAccess"}})],1),(_vm.showCanDeleteUtilization)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":_vm.$t('user.creationForm.formFieldTitles.canDeleteUtilization')},model:{value:(_vm.user.canDeleteUtilization),callback:function ($$v) {_vm.$set(_vm.user, "canDeleteUtilization", $$v)},expression:"user.canDeleteUtilization"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('user.creationForm.formFieldTitles.notes'),"color":"#0071c5"},model:{value:(_vm.user.notes),callback:function ($$v) {_vm.$set(_vm.user, "notes", $$v)},expression:"user.notes"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.isSending || !_vm.isValid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")]),(_vm.isSending)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1),_c('v-snackbar',{staticStyle:{"top":"8px"},attrs:{"top":"","color":_vm.showSnackbarColor,"timeout":3000},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("CLOSE")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }